<template>
    <section class="main-content-section">
        <div class="grid">
            <div class="col-1-1">
                <h1 class="page-title">{{customerService}}</h1>
            </div>

            <LoadingSpinner  v-if="isLoading"/>
            
            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container customer-service">
                    <div class="warning">{{pleaseLeaveYourInformation}}</div>
                    <form @submit.prevent="submitForm()">

                        <div class="form-row flex flex-column">
                            <label for="name">{{nameLabel}} *</label>
                            <input 
                                type="text" 
                                id="name"
                                v-model.trim="name"
                                @input="$v.name.$touch()"
                                :class="{ error: $v.name.$error }" 
                            />
                            <div v-if="$v.name.$dirty">
                                <p class="error" v-if="!$v.name.required">{{nameLabel}} {{isRequired}}.</p>
                                <p class="error" v-if="!$v.name.valid">{{invalidSpecialCharacters}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="email">{{emailAddress}} *</label>
                            <input 
                                id="email" 
                                type="email"
                                v-model.trim="email"
                                @input="$v.email.$touch()"
                                :class="{ error: $v.email.$error }" 
                            />
                            <div v-if="$v.email.$dirty">
                                <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                                <p class="error" v-if="!$v.email.required">{{emailAddress}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="emailConfirmation">{{confirmEmailaddress}} *</label>
                            <input 
                                id="emailConfirmation" 
                                type="email"
                                v-model.trim="emailConfirmation"
                                @input="$v.emailConfirmation.$touch()"
                                :class="{ error: $v.emailConfirmation.$error }" 
                            />
                            <div v-if="$v.emailConfirmation.$dirty">
                                <p class="error" v-if="!$v.emailConfirmation.required">{{confirmEmailaddress}} {{isRequired}}</p>
                                <p class="error" v-if="!$v.emailConfirmation.sameAsEmail">{{incorrectEmailAddressConfirmation}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="reservationNumber">{{orderNumberLabel}}</label>
                            <input 
                                type="text" 
                                id="reservationNumber" 
                                v-model.trim="orderNumber"
                                 @input="$v.orderNumber.$touch()"
                                :class="{ error: $v.orderNumber.$error }"
                            />
                            <div v-if="$v.orderNumber.$dirty">
                                <p class="error" v-if="!$v.orderNumber.valid">{{invalidSpecialCharacters}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="question">{{questionLabel}} *</label>
                            <textarea 
                                type="text" id="question" rows="10"
                                v-model.trim="question"
                                @input="$v.question.$touch()"
                                :class="{ error: $v.question.$error }"
                            ></textarea>
                            <div v-if="$v.question.$dirty">
                                <p class="error" v-if="!$v.question.required">{{questionLabel}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <input type="checkbox" id="terms" v-model="terms" @change="$v.terms.$touch()" :class="{ error: $v.terms.$error }">
                            <label for="terms"><div class="custom-checkbox"></div>{{accept}} <router-link to="/terms">{{termsAndConditions.toLowerCase()}}</router-link> *</label>
                            <div v-if="$v.terms.$dirty && $v.terms.$invalid">
                                <p class="error" v-if="$v.terms.required">{{termsAndConditions}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <input type="checkbox" id="privacyPolicy" v-model="privacyPolicy" @change="$v.privacyPolicy.$touch()" :class="{ error: $v.privacyPolicy.$error }">
                            <label for="privacyPolicy"><div class="custom-checkbox"></div>{{accept}} <router-link to="/privacy">{{privacyPolicyLabel.toLowerCase()}}</router-link> *</label>
                             <div v-if="$v.privacyPolicy.$dirty && $v.privacyPolicy.$invalid">
                                <p class="error" v-if="$v.privacyPolicy.required">{{privacyPolicyLabel}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-center">
                            <button type="submit" class="btn btn-primary submitBtn">{{send}}</button>
                        </div>                      
                    </form>
                </div>
            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
    </section>
</template>

<script>
import LoadingSpinner from '../components/ui/LoadingSpinner.vue'; 
import { required, email, sameAs } from 'vuelidate/lib/validators';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import { mapGetters } from 'vuex';
import agent from '../api/agent';

export default {
    name: 'CustomerService',
    components: { WarningMessageModal, LoadingSpinner },

    data() {
        return {
            name: '',
            email: '',
            emailConfirmation: '',
            orderNumber: '',
            question: '',
            terms: false,
            privacyPolicy: false,
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false,
            isLoading: false
        }
    },

    validations: {
        name: { 
            required,
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            },
         },
        email: { required, email },
        emailConfirmation: { required, sameAsEmail: sameAs("email") },
        orderNumber: {
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            },
        },
        question: { required },
        terms: { required, sameAsChecked: sameAs(() => true) },
        privacyPolicy: {required, sameAsChecked: sameAs(() => true)},
    },

    created() {
        this.setDocumentTitle();
    },

    updated() {
        this.setDocumentTitle();
    },

    computed: {
        ...mapGetters([
            'metaTitle',
            'selectedLanguage',
            'recaptchaValidation',
            // Texts
            'customerService',
            'pleaseLeaveYourInformation',
            'isRequired',
            'indicatesRequiredField',
            'emailAddress',
            'validEmailAddress',
            'confirmEmailaddress',
            'incorrectEmailAddressConfirmation',
            'accept',
            'termsAndConditions',
            'send',
            'notAllRequiredFieldsAreCompleted',
            'thankYou',
            'yourRequestHasBeenSent',
            'invalidSpecialCharacters'
        ]),
        ...mapGetters({
            // Texts
            nameLabel: 'name',
            orderNumberLabel: 'orderNumber',
            questionLabel: 'question',
            privacyPolicyLabel: 'privacyPolicy'
        })
    },

    methods: {
        setDocumentTitle() {
            document.title = this.customerService + this.metaTitle;
        },

        showModal() {
            // this.errorTitle = this.notAllRequiredFieldsAreCompleted;
            // this.errorMessage = fieldTitle + ' ' + this.isRequired;
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        resetFormData() {
            this.name = '';
            this.email = '';
            this.emailConfirmation = '';
            this.orderNumber = '';
            this.question = '';
            this.terms = false;
            this.privacyPolicy = false;

            this.$v.$reset();
        },

        async sendSupportMail() {
            try {
                this.isLoading = true;
                const formData = {
                    name: this.name,
                    email: this.email,
                    confirmEmail: this.emailConfirmation,
                    orderNumber: this.orderNumber,
                    question: this.question,
                    acceptedTerms: this.terms,
                    acceptedPrivacy: this.privacyPolicy,
                    languageNumber: this.selectedLanguage
                };

                await agent.email.sendSupportMail(formData);

                this.isLoading = false;

                this.errorTitle = this.thankYou;
                this.errorMessage = this.yourRequestHasBeenSent;
                this.showModal();
                this.resetFormData();
            } catch (error) {
                if (error) {
                    console.log(error.response);
                    this.isLoading = false;
                    this.errorTitle = error.response.data.title;
                    this.errorMessage = error.response.data.detail;
                    this.showModal();
                }
            }
        },

        async getRecaptchaToken() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded();
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login');
            // Do stuff with the received token.
            this.validateRecaptcha(token);
        },

        async validateRecaptcha(token) {
            await this.$store.dispatch('validateRecaptcha', token);
            if (this.recaptchaValidation === true) {
                this.sendSupportMail();
            } else {
                this.errorTitle = 'Something went wrong';
                this.errorMessage = 'Please try again';
                this.showModal();
            }
        },

        submitForm() {
            // Warning modal message handlers and prevent navigation to next step 
            if (this.$v.name.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.nameLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.email.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.emailAddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.emailConfirmation.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.confirmEmailaddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.question.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.questionLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.terms.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.termsAndConditions + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.privacyPolicy.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.privacyPolicyLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if(!this.$v.$invalid) {
                this.getRecaptchaToken();
            }
        }
    }
}
</script>